// MUI Imports
import type { Theme } from '@mui/material/styles'

const typography: Theme['components'] = {
  MuiCssBaseline: {
    // styleOverrides: (theme) => ({
    //   '.Mui-disabled': {
    //     color: theme.palette.primary.main,
    //     opacity: 0.9,
    //   },
    // })
  }
}

export default typography
